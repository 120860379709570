import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import styled, { css } from "styled-components"
import { media } from "../../styles/media"

import LangLayout from "../../components/LangLayout"
import PageHeaderMedia from "../../components/PageHeaderMedia"
import SEO from "../../components/SEO"

const StyledContents = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 50px auto;
  .conSec {
    padding: 1.62em 0 3.62em;
    h2 {
      color: #3a3a3a;
      font-size: 1.62em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.62em;
      &:before {
        display: block;
        content: "";
        height: 1.32em;
        width: 8px;
        margin-right: 0.32em;
        border-radius: 1px;
        background: ${({ theme }) => theme.colors.primary.red};
      }
    }
    h3 {
      padding: 0.62em 0;
      font-weight: bold;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      &:after {
        display: block;
        content: "";
        height: 2px;
        border-radius: 2px;
        width: 38%;
        background: ${({ theme }) => theme.colors.primary.green};
        margin: 1.62em 0;
      }
    }
    p {
      width: 100%;
      text-align: center;
    }
  }
  .conSection {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    h2 {
      font-size: 1.32em;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 0.62em;
      &:before {
        display: block;
        content: "";
        width: 16%;
        height: 8px;
        border-radius: 3px;
        background: ${({ theme }) => theme.colors.primary.green};
        margin: 1.62em 0 0.62em;
      }
    }
    .gatsby-image-wrapper {
      width: 400px;
      margin: 30px auto;
    }
  }
  ${() =>
    media.sp(css`
      .gatsby-image-wrapper {
        width: 230px !important;
      }
    `)}
`

const LangProductCn = () => {
  const data = useStaticQuery(query)
  return (
    <LangLayout>
      <SEO>
        <title>法兰索亚的商品品牌｜法兰索亚</title>
        <meta
          name="description"
          content="使用天然酵母慢慢发酵 最正宗的面包系列。今天的面包是明天的健康! 100%天然素材的餐桌面包品牌。新口感的弹性和细腻感。 多款吐司面包品牌。美味、快乐、健康。 甜面包品牌。 不含防腐剂 三明治&汉堡包品牌。"
        />
      </SEO>
      <PageHeaderMedia
        fluid={data.zoo.productLangHeader.headerMedia.childImageSharp.fluid}
        pageTitles={data.zoo.productLangHeader.headerTitleCn}
        pageText={data.zoo.productLangHeader.subTitleEn}
      />
      <StyledContents>
        <div className="conSec">
          <h2>{data.zoo.plist.pbTitle}</h2>
        </div>
        <div className="conSection">
          <h2>{data.zoo.plist.pbSlowBreadTitle}</h2>
          <Image
            fluid={data.zoo.plist.pbSlowBreadMedia.childImageSharp.fluid}
            alt="francoisEn"
          />
          <p>{data.zoo.plist.pbSlowBreadTxt}</p>
        </div>
        <div className="conSection">
          <h2>{data.zoo.plist.pbNaturelTitle}</h2>
          <Image
            fluid={data.zoo.plist.pbNaturelMedia.childImageSharp.fluid}
            alt="francoisEn"
          />
          <p>{data.zoo.plist.pbNaturelTxt}</p>
        </div>
        <div className="conSection">
          <h2>{data.zoo.plist.pb100Title}</h2>
          <Image
            fluid={data.zoo.plist.pb100Media.childImageSharp.fluid}
            alt="francoisEn"
          />
          <p>{data.zoo.plist.pb100Txt}</p>
        </div>
        <div className="conSection">
          <h2>{data.zoo.plist.pbWeTitle}</h2>
          <Image
            fluid={data.zoo.plist.pbWeMedia.childImageSharp.fluid}
            alt="francoisEn"
          />
          <p>{data.zoo.plist.pbWeTxt}</p>
        </div>
        <div className="conSection">
          <h2>{data.zoo.plist.pbDeliTitle}</h2>
          <Image
            fluid={data.zoo.plist.pbDeliMedia.childImageSharp.fluid}
            alt="francoisEn"
          />
          <p>{data.zoo.plist.pbDeliTxt}</p>
        </div>
      </StyledContents>
    </LangLayout>
  )
}

export const query = graphql`
  {
    zoo: strapiLangProduct {
      productLangHeader {
        headerTitleCn
        mediaAlt
        headerMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      plist: langProductBrandCn {
        pb100Title
        pb100Txt
        pbDeliTitle
        pbDeliTxt
        pbNaturelTitle
        pbNaturelTxt
        pbSlowBreadTitle
        pbSlowBreadTxt
        pbWeTitle
        pbWeTxt
        pbDeliMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pb100Media {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbNaturelMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbSlowBreadMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbWeMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbTitle
      }
    }
  }
`

export default LangProductCn
